import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../components/global/layout/layout';
import Slice from '../../components/global/slice/slice';

export default ({ data }) => {
    return (
        <Layout title={data.prismicProject.data.meta_title.text}
                description={data.prismicProject.data.meta_description.text}
                image={data.prismicProject.data.meta_image.url}>
          <Slice slices={data.prismicProject.data.body}/>
        </Layout>
    )
}

export const pageQuery = graphql`
  query ProjectByUid($uid: String!) {
    prismicProject(uid: {eq: $uid}) {
      data {
        meta_title {
          text
        }
        meta_image {
          url
        }
        meta_description {
          text
        }
        body {
          __typename
          ... on PrismicProjectBodySchlagworteText {
            primary {
              bulletpoints {
                raw
              }
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyBilderLinks {
            items {
              image {
                url
              }
              link {
                text
              }
              text {
                text
              }
            }
          }
          ... on PrismicProjectBodyBild {
            primary {
              color
              image {
                alt
                url
              }
              link {
                text
              }
              link_text {
                text
              }
              row_1 {
                text
              }
              row_2 {
                text
              }
              row_3 {
                text
              }
            }
          }
          ... on PrismicProjectBodyTextabschnitte {
            items {
              text {
                html
              }
              heading {
                text
              }
            }
          }
          ... on PrismicProjectBodyText {
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyPersonenBereich {
            primary {
              heading {
                text
              }
              text {
                text
                html
              }
            }
            items {
              text {
                html
                text
              }
              position {
                text
                raw
              }
              lastname {
                text
              }
              image {
                url
                alt
              }
              firstname {
                text
              }
              email {
                text
              }
              phone {
                text
              }
            }
          }
          ... on PrismicProjectBodyBildergalerie {
            items {
              image {
                url
                alt
              }
            }
          }
          ... on PrismicProjectBodyProjektTeaser {
            items {
              image {
                alt
                url
              }
              link {
                text
              }
              link_text {
                text
              }
              text {
                html
              }
              title {
                text
              }
            }
          }
          ... on PrismicProjectBodyZitat {
            primary {
              author {
                text
              }
              image {
                alt
                url
              }
              text {
                html
              }
            }
          }
          ... on PrismicProjectBodyVorteile {
            primary {
              heading {
                text
              }
              image {
                url
                alt
              }
              items {
                raw
              }
            }
          }
          ... on PrismicProjectBodyBildHeader {
            primary {
              color
              image {
                alt
                url
              }
              row_1 {
                text
              }
              row_2 {
                text
              }
              row_3 {
                text
              }
            }
          }
          ... on PrismicProjectBodyUberschriftUndText {
            primary {
              text {
                html
              }
              heading {
                text
              }
            }
          }
          ... on PrismicProjectBodyProzessKarten {
            items {
              text {
                html
              }
              title {
                text
              }
              link {
                text
              }
            }
          }
          ... on PrismicProjectBodyIframe {
            primary {
              html {
                text
              }
              responsive
            }
          }
        }
      }
    }
  }
`
